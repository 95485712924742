import * as actionTypes from '../actions/actionTypes'
import { del, push, set } from 'object-path-immutable'
import _ from 'lodash'

const initialState = {
  projects: null,
  projectOnServer: null,
  project: null,
  pendingRequests: [],
  projectsForRequests: null
}

const newProject = {
  id: null,
  name: '',
  description: '',
  notification_time: '21:00:00',
  last_updated: null,
  is_billable_hours_enabled: true
}

const reducer = (state = initialState, action) => {
  let updatedState
  switch (action.type) {
    case 'CLEAR_CURRENT_PROJECT':
      return { ...state, project: null }
    case actionTypes.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        projectOnServer: action.project,
        project: action.project
      }
    case actionTypes.LOAD_PROJECT_FAILURE:
      return { ...state, projectOnServer: null, project: null }
    case actionTypes.LOAD_PROJECTS_SUCCESS:
      return { ...state, projects: action.projects }
    case actionTypes.LOAD_PROJECTS_FOR_REQUESTS_SUCCESS:
      return { ...state, projectsForRequests: action.projects }
    case actionTypes.LOAD_PROJECTS_FAILURE:
      return { ...state, projects: null, projectsForRequests: null }
    case actionTypes.SAVE_PROJECT_SUCCESS:
      if (!state.projectOnServer.id)
        updatedState = push(state, `projects`, action.project)
      else
        updatedState = state
      return {
        ...state,
        projects: updatedState.projects,
        projectOnServer: action.project,
        project: action.project,
        message: `Success!!! action.project.new_managers_ids`,
        snackbarOpen: true
      }
    case actionTypes.SAVE_PROJECT_FAILURE:
      return { ...state }
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projectOnServer: action.project,
        project: action.project
      }
    case actionTypes.DELETE_PROJECT_FAILURE:
      return { ...state, project: state.projectOnServer }
    case actionTypes.ADD_NEW_PROJECT:
      return { ...state, projectOnServer: newProject, project: newProject }
    case actionTypes.REMOVE_SELECTED_PROJECT:
      return { ...state, projectOnServer: null, project: null }
    case actionTypes.REMOVE_LOADED_PROJECTS:
      return { ...state, projects: null }
    case actionTypes.REMOVE_LOADED_PROJECTS_FOR_REQUESTS:
      return { ...state, projectsForRequests: null }
    case actionTypes.SET_PROJECT_CHATROOM:
      updatedState = set(state, `project.${action.roomType}`, action.room)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_MANAGERS:
      updatedState = action.isNew
        ? push(state, `project.managers`, action.managers)
        : set(state, `project.managers`, action.managers)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_EMPLOYEES:
      updatedState = set(state, `project.employees`, action.employees)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_SENIOR:
      updatedState = set(state, `project.current_senior_manager`, action.senior)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_TABLE_MODE:
      updatedState = set(state, `project.table_mode`, action.table_mode)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_HUBSTAFF_ENABLED:
      updatedState = set(
        state,
        `project.is_hubstaff_enabled`,
        action.hubstaff_enabled
      )
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_COMMERCIAL:
      updatedState = set(state, `project.is_commercial`, action.is_commercial)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_BILLABLE_HOURS_ENABLED:
      updatedState = set(
        state,
        'project.is_billable_hours_enabled',
        action.billable_hours_enabled
      )
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_SEND_OVERDUE_REPORT_NOTIFICATION:
      updatedState = set(
        state,
        'project.send_overdue_report_notification',
        action.send_overdue_report_notification
      )
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_MILESTONE_REQUIRED:
      updatedState = set(
        state,
        `project.is_milestone_required`,
        action.milestone_required
      )
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_SEND_BB_INFO_TO_GENERAL_CHAT:
      updatedState = set(
        state,
        `project.send_bb_info_to_general_chat`,
        action.send_bb_info_to_general_chat
      )
      return { ...state, project: updatedState.project }
    case 'IS_MARKETPLACE_BILLING':
      updatedState = set(state, `project.is_marketplace_billing`, action.isMarketPlaceBilling)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_NAME:
      updatedState = set(state, `project.name`, action.name)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_GIT_LINKS:
      updatedState = set(state, `project.git_links`, action.gitLinks)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_JENKINS_JOBS:
      updatedState = set(state, `project.jenkins_jobs`, action.jenkinsJobs)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_ALERT_RULES_IDS:
      updatedState = set(state, `project.alert_rules_check_ids`, action.alertRulesIDs)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_CHECK_IDS:
      updatedState = set(state, `project.alert_rules_check_project_ids`, action.projectCheckIDs)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_DESCRIPTION:
      updatedState = set(state, `project.description`, action.description)
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_TECHNOLOGIES:
      updatedState = action.isNew
        ? push(state, `project.technologies`, action.technologies)
        : set(state, `project.technologies`, action.technologies)
      return { ...state, project: updatedState.project }
    case actionTypes.REQUEST_PROJECT_ACCESS_SUCCESS:
      updatedState = push(state, `pendingRequests`, action.accessRequest)
      return { ...state, pendingRequests: updatedState.pendingRequests }
    case actionTypes.LOAD_PROJECT_REQUESTS_SUCCESS:
      return { ...state, pendingRequests: action.pendingRequests }
    case actionTypes.LOAD_PROJECT_REQUESTS_FAILURE:
      return { ...state, pendingRequests: [] }
    case actionTypes.SET_PROJECT_NOTIFICATION_TIME:
      updatedState = set(
        state,
        `project.notification_time`,
        action.notificationTime
      )
      return { ...state, project: updatedState.project }
    case actionTypes.SET_PROJECT_NOTIFICATION_REMINDER_TODAY:
      updatedState = set(
        state,
        `project.notification_reminder_today`,
        action.notificationReminderToday
      )
      return { ...state, project: updatedState.project }
    case actionTypes.ADD_PROJECT_SENTRY:
      updatedState = push(state, `project.sentry_projects`, {
        id: 0,
        slug: '',
        threshold: 0,
        _key: crypto.randomUUID()
      })
      return { ...state, project: updatedState.project }
    case actionTypes.DELETE_PROJECT_SENTRY:
      updatedState = set(
        state,
        `project.sentry_projects`,
        state.project.sentry_projects.filter((sentry) => sentry._key !== action.payload)
      )
      return updatedState
    case actionTypes.UPDATE_PROJECT_SENTRY_FIELD:
      updatedState = set(
        state,
        `project.sentry_projects`,
        state.project.sentry_projects.map((sentry, idx) => {
          let new_sentry = _.cloneDeep(sentry)
          if (idx === action.sentryIdx)
            new_sentry[action.field] = action.value

          return new_sentry
        })
      )
      return updatedState
    case actionTypes.DELETE_ORG_FIELD_FROM_SENTRY: {
      const { index } = action.payload
      updatedState = del(state, `project.sentry_projects.${index}.sentry_org_id`)
      return updatedState
    }
    default:
      return state
  }
}

export default reducer
